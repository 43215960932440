import './stlyes/CardInfo.css';
import {Button, Row, Container, Col, Spinner} from 'react-bootstrap';
import apiService from "../apiService";
import {useState, useEffect} from 'react';
import EditModal from "./Modals/EditModal";
import Swal from "sweetalert2";
import customFilters from "../customFilters";


function CardInfo(props) {
    const [deleteAccountPending, setDeleteAccountPending] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditModalClose = () => setShowEditModal(false);
    const handleEditModalShow = () => setShowEditModal(true);

    function timeDifference(startDate) {
        if(!startDate){
            return ''
        }
        const startTimeStamp = new Date(new Date(startDate).toISOString()).getTime();
        const nowTimeStamp = new Date(new Date().toISOString()).getTime();
        const diffMilliSec = nowTimeStamp - startTimeStamp;
        return Math.floor(diffMilliSec / 60000)
    }

    async function deleteAccount(accountId) {
        setDeleteAccountPending(true);
        try {
            const response = await apiService.delete(`${apiService.baseUrl}/delete-account/${accountId}`);
            setDeleteAccountPending(false);
            props.getDataAgain();
            console.log(response);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Confirm',
                timer: 5000,
                timerProgressBar: true,
            })
            console.error(error);
            setDeleteAccountPending(false);
        }
    }


    return (
        <div>
            <Container className="cardContainer">
                <Row>
                    <Col xs={12}>
                        <Button variant="success" onClick={handleEditModalShow}>
                            Edit This Account
                        </Button>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Username:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {props.cardData.username}
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            Transfer Pass:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {props.cardData.transferPass}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Destination:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                        {props.cardData.destination}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Account Number:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {props.cardData.accountNumber}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Remaining Balance:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {customFilters.commaSep(props.cardData.remainingBalance)}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Was Banned:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>

                            {props.cardData.wasBanned ? "True" : "False"}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Last Success Transaction:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {customFilters.dateFilter(props.cardData.lastSuccessTransaction)}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Time since Last Success:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {timeDifference(props.cardData.lastSuccessTransaction)} min
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Time since Last successful login:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {timeDifference(props.cardData.lastSuccessLogin)} min
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Last Failed Transaction:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {customFilters.dateFilter(props.cardData.lastFailedTransaction)}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                            Last Failed Login:
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {new Date(props.cardData.lastLoginFailed).toLocaleString() || ""}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                       Last Insufficient Balance
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {props.cardData.lastInsufficientBalance === "" ? "0" : props.cardData.lastInsufficientBalance}
                        </span>
                    </Col>
                </Row>
                <Row className="eachRow">
                    <Col xs={6}>
                        <span>
                       Active
                        </span>
                    </Col>
                    <Col xs={6}>
                        <span>
                            {props.cardData.active ? "True" : "False"}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} style={{paddingTop: "15px"}}>
                        {/*Delete Button*/}

                        <Button variant="danger" onClick={() => {
                            deleteAccount(props.cardData._id)
                        }}
                                style={{background: "#da2255", border: "none", marginBottom: "5px"}}>
                            {!deleteAccountPending ?
                                <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                     height="25" fill="currentColor"
                                     className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                                </svg>
                            </span>
                                :
                                <span>
                                    <Spinner animation="border" role="status"
                                             style={{width: "1.1rem", height: "1.1rem"}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </span>
                            }


                        </Button>
                    </Col>
                </Row>
            </Container>
            <EditModal showEditModal={showEditModal} handleEditModalShow={handleEditModalShow}
                       getDataAgain={props.getDataAgain}
                       accountId={props.cardData._id}
                       cardData={props.cardData}
                       handleEditModalClose={handleEditModalClose}/>
        </div>
    );
}

export default CardInfo;
