import Header from "./Header";
import CardInfo from "./CardInfo";
import CreateAccountModal from "./Modals/CreateAccountModal";
import AddTransactionModal from "./Modals/AddTransactionModal";
// import EditModal from "./EditModal";
import './stlyes/Layout.css';
import apiService from "../apiService";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

import {Button, Row, Container, Spinner} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import TransactionTable from "./TransactionTable";
import Logs from "./Logs";


function Layout() {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateTxModal, setShowCreateTxModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cardData, setCardData] = useState([]);
    const [notification, setNotification] = useState({});
    const [getDataAgain, setGetDataAgain] = useState(false);
    const [whichPage, setWhichPage] = useState(0);
    const [transactionsRefreshSignal, setTxRefreshSignal] = useState(null);
    const [logsRefreshSignal, setLogsRefreshSignal] = useState(null);

    const handleCreateModalClose = () => setShowCreateModal(false);
    const handleCreateModalShow = () => setShowCreateModal(true);
    const handleEditModalClose = () => setShowEditModal(false);
    const handleEditModalShow = () => setShowEditModal(true);
    const handleSetGetDataAgain = () => setGetDataAgain(!getDataAgain);
    const handleShowCreateTxModal = () => setShowCreateTxModal(true);
    const handleCloseCreateTxModal = () => setShowCreateTxModal(false);
    const handleTransactionRefresh = () => setTxRefreshSignal(new Date().getTime());
    const handleLogsRefresh = () => setLogsRefreshSignal(new Date().getTime());

    useEffect(() => {
        getData();
        // Notf();
    }, [getDataAgain])

    function notifyMe(account) {
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            const notificationOptions = {
                body: "Account Number: " + account.accountNumber + " Transfer Pass: " + account.transferPass + " This Account is banned",
            };
            // If it's okay let's create a notification
            setNotification(new Notification("Warning", notificationOptions));

        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notificationOptions = {
                        body: "Account Number: " + account.accountNumber + " Transfer Pass: " + account.transferPass + " This Account is banned",
                    };
                    setNotification(new Notification("Warning", notificationOptions));
                }
            });
        }
    }

    async function getData() {
        setIsLoading(true);
        try {
            const response = await apiService.get(`${apiService.baseUrl}/get-accounts`);
            setCardData(response.data);
            setIsLoading(false);
            for (let eachCard of cardData) {
                if (eachCard.wasBanned) {
                    notifyMe(eachCard);
                }
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Confirm',
                timer: 5000,
                timerProgressBar: true,
            })
            console.error(error);
            setIsLoading(false);
        }
    }

    //Main Card Element and Mapping the CardInfo component
    function cardRow() {
        return (
            <Row className="d-flex justify-content-center align-items-center">
                {cardData.length > 0 ? cardData.map((card, index) => (
                        (<CardInfo cardData={card} key={index} getDataAgain={handleSetGetDataAgain}/>)
                    ))
                    : "No Cards Available"}
            </Row>
        );
    }

    function renderLogs() {
        return (
            <Row className="d-flex justify-content-center align-items-center">
                <Logs refreshSignal={logsRefreshSignal}/>
            </Row>
        );
    }

    function transactionTable() {
        return (
            <Row className="d-flex justify-content-center align-items-center">
                <TransactionTable refreshSignal={transactionsRefreshSignal}/>
            </Row>
        );
    }

    function showFloatBtn (page) {
        if (page === 1) {
            return <Button
                className="d-flex justify-content-center align-items-center flex-column addAccountButton floatButton"
                onClick={handleShowCreateTxModal}>
                        <span style={{color: "white"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-plus-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </span>
                <span>
                        Transaction
                    </span>
            </Button>
        } else {
            return <Button
                className="d-flex justify-content-center align-items-center flex-column addAccountButton floatButton"
                onClick={handleCreateModalShow}>
                        <span style={{color: "white"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-plus-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </span>
                <span>
                        Account
                    </span>
            </Button>
        }
    }

    return (
        <div style={{width: "100%", height: "100vh"}}>
            <Container style={{paddingTop: "2%"}} className="mainContainer">
                <Header modalVisibility={setShowCreateModal} whichPage={whichPage}
                        switchTab={(tab) => setWhichPage(tab)} //handling page change
                />
                {/*account detail page loading*/}
                {isLoading && whichPage === 0
                    ?
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    : ""
                }
                {/*mapping CardInfo*/}
                {whichPage === 0 ? cardRow() : ""}

                {/*transaction table page*/}
                {whichPage === 1 ? transactionTable() : ""}

                {whichPage === 2 ? renderLogs() : ""}

                {showFloatBtn(whichPage)}

            </Container>
            <CreateAccountModal showModal={showCreateModal} handleModalShow={handleCreateModalShow}
                                getDataAgain={handleSetGetDataAgain}
                                handleModalClose={handleCreateModalClose}/>
            <AddTransactionModal showModal={showCreateTxModal} handleModalShow={handleShowCreateTxModal}
                                 handleModalClose={handleCloseCreateTxModal} refreshTransactionsList={handleTransactionRefresh}/>
            {/*<EditModal showEditModal={showEditModal} handleEditModalShow={handleEditModalShow}*/}
            {/*           handleEditModalClose={handleEditModalClose}/>*/}
        </div>
    );
}

export default Layout;
