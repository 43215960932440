// import Header from "./Header";
// import CardInfo from "./CardInfo";
import '../stlyes/Layout.css';
import {Button, Row, Container, Col, Card, Modal, Form, Spinner} from 'react-bootstrap';
import apiService from "../../apiService";
import {useState, useEffect} from 'react';
import customFilters from "../../customFilters";
import Swal from "sweetalert2";


function AddTransactionModal(props) {
    const [transactionDetails, setTransactionDetails] = useState({
        sourceCard: '',
        lastDigits: '',
        traceNumber: '',
        amount: 0,
        destinationCardNumber: '',
        destinationAccountNumber: '',
        date: '',
        time: '',
        year: '1401',
        month: '',
        day: '',
        hour: '',
        minute: ''

    });
    const [createTransactionPending, setCreateTransactionPending] = useState(false);


    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        setTransactionDetails(values => ({...values, [name]: value}))
    }

    function clearInputs() {
        setTransactionDetails({});
    }

    // const createCard = useCallback(async () => {
    //     prepareIntInputForApi();
    //     createAccount();
    //
    // }, []);
    //
    // function prepareIntInputForApi() {
    //     let remainingBalanceApiVal = accountDetails.remainingBalance;
    //     remainingBalanceApiVal = customFilters.removeComma(remainingBalanceApiVal);
    //     remainingBalanceApiVal = parseInt(remainingBalanceApiVal);
    //     setAccountDetails({...accountDetails, remainingBalance: remainingBalanceApiVal})
    //     console.log(accountDetails);
    //     console.log(typeof accountDetails.remainingBalance);
    // }

    async function createTransaction() {
        console.log(props);
        //It's not changing the value after one click but after two, it will change
        setCreateTransactionPending(true);
        try {
            const {year, month, day, hour, minute, ...newTransaction} = transactionDetails;
            newTransaction.date = (year && month && day) ? `${year}/${month}/${day}` : "";
            newTransaction.time = (hour && minute) ? `${hour}:${minute}` : "";
            const response = await apiService.post(`${apiService.baseUrl}/add-transaction`, newTransaction, {
                headers: {
                    "x-api-key": "$2y$10$VfRpqrAqPJD2QCP9GfkyAOL45m6wkMvmYC4aVGIemZk24YzH5T35q"
                }
            });
            setCreateTransactionPending(false);
            await Swal.fire({
                title: 'Success',
                text: 'Transaction Added',
                icon: 'success',
                confirmButtonText: 'Confirm',
                timer: 3000,
                timerProgressBar: true,
            })
            props.handleModalClose();
            props.refreshTransactionsList();
            clearInputs();
        } catch (error) {
            await Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Confirm',
                timer: 5000,
                timerProgressBar: true,
            })
            console.error(error);
            setCreateTransactionPending(false);
        }
    }

    return (
        <div>
            <Modal show={props.showModal} onHide={props.handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add To List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formSourceCard">
                            <Form.Label>Source card Number *</Form.Label>
                            <Form.Control type="text" placeholder="Enter source card" name="sourceCard"
                                          value={transactionDetails.sourceCard || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLastDigits">
                            <Form.Label>Last 4 digits *</Form.Label>
                            <Form.Control type="text" placeholder="Source Card Last 4 Digits" name="lastDigits"
                                          value={transactionDetails.lastDigits || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="amount">
                            <Form.Label>Amount *</Form.Label>
                            <Form.Control type="text" placeholder="Enter Transaction Amount" name="amount"
                                          value={transactionDetails.amount || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="traceNumber">
                            <Form.Label>Trace Number *</Form.Label>
                            <Form.Control type="text" placeholder="Enter Trace Number" name="traceNumber"
                                          value={transactionDetails.traceNumber || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="destinationCardNumber">
                            <Form.Label>Destination Card Number *</Form.Label>
                            <Form.Control type="text" placeholder="Enter Destination Card" name="destinationCardNumber"
                                          value={transactionDetails.destinationCardNumber || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="destinationAccountNumber">
                            <Form.Label>Destination Account</Form.Label>
                            <Form.Control type="text" placeholder="Enter Destination Account Number" name="destinationAccountNumber"
                                          value={transactionDetails.destinationAccountNumber || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="date">
                            <Col sm="4">
                                <Form.Label>Year</Form.Label>
                                <Form.Control type="text" placeholder="Year" name="year"
                                              value={transactionDetails.year || ""} onChange={handleChange}/>
                            </Col>
                            <Col sm="4">
                                <Form.Label>Month</Form.Label>
                                <Form.Control type="text" placeholder="Month" name="month"
                                              value={transactionDetails.month || ""} onChange={handleChange}/>
                            </Col>
                            <Col sm="4">
                                <Form.Label>Day</Form.Label>
                                <Form.Control type="text" placeholder="Day" name="day"
                                              value={transactionDetails.day || ""} onChange={handleChange}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="date">
                            <Col sm="6">
                                <Form.Label>Hour</Form.Label>
                                <Form.Control type="text" placeholder="Hour" name="hour"
                                              value={transactionDetails.hour || ""} onChange={handleChange}/>
                            </Col>
                            <Col sm="6">
                                <Form.Label>Minute</Form.Label>
                                <Form.Control type="text" placeholder="Minute" name="minute"
                                              value={transactionDetails.minute || ""} onChange={handleChange}/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Col xs={12} className="justify-content-center align-items-center d-flex">
                        <Button variant="success" style={{width: "35%"}} onClick={createTransaction}>
                            <span>
                                {createTransactionPending
                                    ?
                                    <Spinner animation="border" role="status"
                                             style={{width: "1.3rem", height: "1.3rem"}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    : "Submit"
                                }
                            </span>
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddTransactionModal;
