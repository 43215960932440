// import Header from "./Header";
// import CardInfo from "./CardInfo";
import '../stlyes/Layout.css';
import apiService from "../../apiService";
import {Button, Row, Container, Col, Card, Modal, Form, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import customFilters from "../../customFilters";
import Swal from "sweetalert2";


function EditModal(props) {

    const [accountDetails, setAccountDetails] = useState({
        username: '',
        password: '',
        accountNumber: '',
        transferPass: '',
        destination: '',
        remainingBalance: '',
        active: false,
    });
    const [createAccountPending, setCreateAccountPending] = useState(false);
    const [remainingBalance, setRemainingBalance] = useState('');
    const handleSetBasicRemaingData = (value) => setRemainingBalance(value);


    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'active') {
            value = event.target.checked;
        }
        if (name === 'remainingBalance') {
            setRemainingBalance(event.target.value);
            value = customFilters.removeComma(remainingBalance);
            value = parseInt(value);
        }
        setAccountDetails(values => ({...values, [name]: value}))
    }

    // function filterTheRemBalanceInput(val) {
    //     // setRemainingBalance(props.cardData.remainingBalance)
    //     let newVal = '';
    //     if (!val)
    //         val = '';
    //     else {
    //         newVal = val.replaceAll(',', '');
    //     }
    //     // setRemainingBalance(customFilters.commaSep(newVal));
    //     return customFilters.commaSep(newVal);
    //     // setAccountDetails({...accountDetails, remainingBalance: customFilters.commaSep(newVal)})
    //     // console.log(accountDetails.remainingBalance);
    //     // setRemainingBalance(customFilters.commaSep(newVal));
    // }

    function filterRemaining(val) {
        let newVal = '';
        if (!val)
            val = '';
        else {
            newVal = val.replaceAll(',', '');
            setRemainingBalance(customFilters.commaSep(newVal));
        }
        // return customFilters.commaSep(newVal);
        // setAccountDetails({...accountDetails, remainingBalance: customFilters.commaSep(newVal)})
        // console.log(accountDetails.remainingBalance);
        // setRemainingBalance(customFilters.commaSep(newVal));
    }

    function clearInputs() {
        setAccountDetails({});
    }

    function setBaseAccountDetails() {
        setRemainingBalance(customFilters.commaSep(props.cardData.remainingBalance + ''));
        setAccountDetails(props.cardData);
    }

    async function updateAccount() {
        setCreateAccountPending(true);
        try {
            const response = await apiService.post(`${apiService.baseUrl}/update-account`, {
                id: props.accountId,
                ...accountDetails,
                remainingBalance: +((accountDetails.remainingBalance + '').replaceAll(",", ""))
            });
            setCreateAccountPending(false);
            Swal.fire({
                title: 'Success',
                text: 'Card Edited',
                icon: 'success',
                confirmButtonText: 'Confirm',
                timer: 3000,
                timerProgressBar: true,
            })
            props.getDataAgain();
            props.handleEditModalClose();
            clearInputs();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Confirm',
                timer: 5000,
                timerProgressBar: true,
            })
            console.error(error);
            setCreateAccountPending(false);
        }
    }

    useEffect(() => {
        setBaseAccountDetails();
    }, [])

    useEffect(() => {
        filterRemaining(remainingBalance);
    }, [remainingBalance])

    return (
        <div>
            <Modal show={props.showEditModal} onHide={props.handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit This Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formUserName">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter User Name" name="username"
                                          value={accountDetails.username || ""} onChange={handleChange}/>

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" name="password"
                                          value={accountDetails.password || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTransferPass">
                            <Form.Label>Transfer Pass</Form.Label>
                            <Form.Control type="text" placeholder="Enter Transfer Password" name="transferPass"
                                          value={accountDetails.transferPass || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDestination">
                            <Form.Label>Destination</Form.Label>
                            <Form.Control type="text" placeholder="Enter Destination" name="destination"
                                          value={accountDetails.destination || ""} onChange={handleChange}/>
                        </Form.Group>
                        {/*<Form.Group className="mb-3" controlId="formRemainingBalance">*/}
                        {/*    <Form.Label>Remaining Balance</Form.Label>*/}
                        {/*    <Form.Control type="text" placeholder="Enter Remaining Balance in Rials"*/}
                        {/*                  name="remainingBalance" value={filterTheRemBalanceInput(accountDetails.remainingBalance + '')}*/}
                        {/*                  onChange={handleChange}/>*/}
                        {/*    <Form.Text className="text-muted">*/}
                        {/*        Remaining Balance must be in Rial not Toman.*/}
                        {/*    </Form.Text>*/}
                        {/*</Form.Group>*/}
                        <Form.Group className="mb-3" controlId="formRemainingBalance">
                            <Form.Label>Remaining Balance</Form.Label>
                            <Form.Control type="text" placeholder="Enter Remaining Balance in Rials"
                                          name="remainingBalance" value={remainingBalance || ''}
                                          maxLength="15"
                                          onChange={handleChange}/>
                            <Form.Text className="text-muted">
                                Remaining Balance must be in Rial not Toman.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTransferPass" style={{
                            display: "flex",
                            justifyContent: "end",
                            alignContent: "center",
                            alignItems: "center"
                        }}>
                            <Form.Label style={{marginRight: "10px", marginTop: "10px"}}>Active</Form.Label>
                            <Form.Check
                                style={{fontSize: "1.5rem"}}
                                type="switch"
                                id="custom-switch"
                                name="active"
                                value={accountDetails.active}
                                onClick={handleChange}
                                checked={accountDetails.active}
                            />
                        </Form.Group>
                        {/*<Form.Group className="mb-3" controlId="formBasicCheckbox">*/}
                        {/*    <Form.Check type="checkbox" label="Check me out" />*/}
                        {/*</Form.Group>*/}
                        {/*<Button variant="primary" type="submit">*/}
                        {/*    Submit*/}
                        {/*</Button>*/}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Col xs={12} className="justify-content-center align-items-center d-flex">
                        {createAccountPending
                            ?
                            <Button variant="success" style={{width: "35%"}}>
                                <Spinner animation="border" role="status" style={{width: "1.3rem", height: "1.3rem"}}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </Button>
                            :
                            <Button variant="success" style={{width: "35%"}} onClick={updateAccount}>
                                Save
                            </Button>
                        }
                    </Col>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EditModal;