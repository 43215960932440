import './stlyes/Header.css';
import {Button, Row, Container, Col,} from 'react-bootstrap';

// import { BsPlus } from "react-icons/bs";


function Header(props) {
    return (
        <div>
            <Row style={{paddingTop: "15px", paddingBottom: "15px"}}>
                <Col className="col-4 d-flex justify-content-center align-items-center">
                    <Button className="mainButtons" variant={`${props.whichPage === 0 ? 'success' : 'primary'}`}
                            onClick={() => props.switchTab(0)}>
                        <span>
                            Account List
                        </span>
                    </Button>
                </Col>
                <Col className="col-4 d-flex justify-content-center align-items-center">
                    <Button className="mainButtons" variant={`${props.whichPage === 1 ? 'success' : 'primary'}`}
                            onClick={() => props.switchTab(1)}>
                        <span>
                            Transactions
                        </span>
                    </Button>
                </Col>
                <Col className="col-4 d-flex justify-content-center align-items-center">
                    <Button className="mainButtons" variant={`${props.whichPage === 2 ? 'success' : 'primary'}`}
                            onClick={() => props.switchTab(2)}>
                        <span>
                            Logs
                        </span>
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default Header;
