function commaSepFilter(value) {
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removeCommaFilter(value) {
    return String(value).replaceAll(',', '');
}

function dateFilter(value) {
    return value.toString().substr(11, 5) + ' ' + value.toString().substr(0, 10);
}

export default {
    commaSep: commaSepFilter,
    removeComma: removeCommaFilter,
    dateFilter: dateFilter
}