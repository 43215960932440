import './stlyes/CardInfo.css';
import {Button, Row, Container, Col, Spinner, Table} from 'react-bootstrap';
import customFilters from "../customFilters";
import {useState, useEffect} from 'react';
import AddTransactionModal from "./Modals/AddTransactionModal";
import Pagination from 'react-bootstrap/Pagination';
import apiServiceWithToken from "../apiService";
import Swal from "sweetalert2";
import apiService from "../apiService";
import CardInfo from "./CardInfo";
import {tab} from "@testing-library/user-event/dist/tab";

function TransactionTable(props) {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCreateModalClose = () => setShowCreateModal(false);
    const handleCreateModalShow = () => setShowCreateModal(true);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState({});
    //Each page table rows
    const [eachPageTablSize, setEachPageTableSize] = useState(50);
    //Total number of pagination pages
    const [paginationPageNumber, setPaginationPageNumber] = useState('');
    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(5);

    async function getTableData() {
        setIsLoading(true);
        try {
            const response = await apiServiceWithToken.get(`${apiService.baseUrl}/get-latest-transactions`);
            setTableData(response.data);
            setIsLoading(false);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Confirm',
                timer: 5000,
                timerProgressBar: true,
            })
            console.error(error);
            setIsLoading(false);
        }
    }

    function paginationSection() {
        if (paginationPageNumber > 0) {
            let tmp = [];
            for (let i = 0; i < paginationPageNumber; i++) {
                tmp.push(i + 1);
            }
            return (
                <li className={`page-item d-flex`} style={{cursor: "pointer"}}>
                    {tmp.length > 0 ? tmp.map((table, index) => (
                            (
                                <a className={`page-link ${currentPage === index ? 'paginationActive' : 'paginationButton'}`}
                                   key={index} onClick={() => setCurrentPage(index)}>
                                    {table}
                                </a>)
                        ))
                        : ""}
                </li>
            );
        }

    }

    const nextPage = () => {
        if (currentPage !== paginationPageNumber - 1)
            setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if (currentPage !== 0)
            setCurrentPage(currentPage - 1)
    }

    useEffect(() => {
        setPaginationPageNumber(Math.ceil(tableData.length / eachPageTablSize))
        paginationSection();

    }, [isLoading])

    useEffect(() => {
        getTableData();
        //TODO: maybe save the current page so if the user changed the page it still have the page number saved, for better ux
        setCurrentPage(0);
    }, [])

    useEffect(() => {
        if (props.refreshSignal) getTableData()
    }, [props.refreshSignal])

    function createdTable() {
        const minTableIndex = currentPage * eachPageTablSize;
        const maxTableIndex = minTableIndex + eachPageTablSize - 1;
        return (
            <Table striped bordered hover size="sm" responsive="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Source Card Number</th>
                    <th>Last Card Digits</th>
                    <th>Trace Number</th>
                    <th>Date & Time</th>
                    <th>Amount</th>
                    <th>Destination Card Number</th>
                    <th>Remained Balance</th>
                    <th>Created At</th>
                </tr>
                </thead>
                <tbody>
                {tableData.length > 0 ? tableData.map((table, index) => {
                    if (index >= minTableIndex && index <= maxTableIndex)
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{table.sourceCardNumber}</td>
                                <td>{table.lastDigitsCardNumber}</td>
                                <td>{table.traceNumber}</td>
                                <td>{table.date} {table.time}</td>
                                <td>{customFilters.commaSep(table.amount)}</td>
                                <td>{table.destinationCardNumber}</td>
                                <td>{table.remainedBalance ? customFilters.commaSep(table.remainedBalance) : "-"}</td>
                                <td>{new Date(table.createdAt).toLocaleString()}</td>
                            </tr>
                        )

                }) : ""}
                </tbody>
            </Table>
        )
    }

    return (
        <div>
            {!isLoading ? <Container className="cardContainer" style={{paddingTop: "15px"}}>
                    {/*TODO: responsive...*/}
                    {createdTable()}
                    <Row className={'col-12 d-flex justify-content-center align-items-center'}>

                        <Col className={'col-12 d-flex justify-content-center align-items-center'}>
                            <nav>
                                <ul className={'pagination-sm justify-content-center d-flex'}>
                                    <li className="page-item d-flex">
                                        <a className="page-link d-flex justify-content-center align-items-center paginationButton"
                                           href='#' onClick={prevPage}>

                                            {'<'}
                                        </a>
                                    </li>
                                    {paginationSection()}

                                    <li className="page-item d-flex">
                                        <a className="page-link d-flex justify-content-center align-items-center paginationButton"
                                           href='#' onClick={nextPage}>

                                            {'>'}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                </Container>
                : <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}

            {/*modals*/}
            {/*<AddTransactionModal showModal={showCreateModal} handleModalShow={handleCreateModalShow}
                                 handleModalClose={handleCreateModalClose}/>*/}
        </div>
    );
}

export default TransactionTable;
