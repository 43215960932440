// import Header from "./Header";
// import CardInfo from "./CardInfo";
import '../stlyes/Layout.css';
import {Button, Row, Container, Col, Card, Modal, Form, Spinner} from 'react-bootstrap';
import apiService from "../../apiService";
import {useState, useEffect} from 'react';
import customFilters from "../../customFilters";
import Swal from "sweetalert2";


function CreateAccountModal(props) {
    const [accountDetails, setAccountDetails] = useState({
        username: '',
        password: '',
        accountNumber: '',
        transferPass: '',
        destination: '',
        remainingBalance: '',
        active: false,
    });
    const [createAccountPending, setCreateAccountPending] = useState(false);
    const [remainingBalance, setRemainingBalance] = useState('');


    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'active') {
            value = event.target.checked;
        }
        if (name === 'remainingBalance') {
            setRemainingBalance(event.target.value);
            value = customFilters.removeComma(event.target.value);
            value = parseInt(value);
        }
        setAccountDetails(values => ({...values, [name]: value}))
    }

    function clearInputs() {
        setAccountDetails({});
    }

    // const createCard = useCallback(async () => {
    //     prepareIntInputForApi();
    //     createAccount();
    //
    // }, []);
    //
    // function prepareIntInputForApi() {
    //     let remainingBalanceApiVal = accountDetails.remainingBalance;
    //     remainingBalanceApiVal = customFilters.removeComma(remainingBalanceApiVal);
    //     remainingBalanceApiVal = parseInt(remainingBalanceApiVal);
    //     setAccountDetails({...accountDetails, remainingBalance: remainingBalanceApiVal})
    //     console.log(accountDetails);
    //     console.log(typeof accountDetails.remainingBalance);
    // }

    async function createAccount() {
        //It's not changing the value after one click but after two, it will change
        setCreateAccountPending(true);
        try {
            const response = await apiService.post(`${apiService.baseUrl}/create-account`, accountDetails);
            setCreateAccountPending(false);
            Swal.fire({
                title: 'Success',
                text: 'Card Added',
                icon: 'success',
                confirmButtonText: 'Confirm',
                timer: 3000,
                timerProgressBar: true,
            })
            props.handleModalClose();
            props.getDataAgain();
            clearInputs();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Confirm',
                timer: 5000,
                timerProgressBar: true,
            })
            console.error(error);
            setCreateAccountPending(false);
        }
    }

    function filterTheRemBalanceInput(val) {
        let newVal = '';
        if (!val)
            val = '';
        else {
            newVal = val.replaceAll(',', '');
        }
        // setAccountDetails({...accountDetails, remainingBalance: customFilters.commaSep(newVal)})
        // console.log(accountDetails.remainingBalance);
        setRemainingBalance(customFilters.commaSep(newVal));
        console.log(remainingBalance);
    }

    // useEffect(() => {
    //     console.log(typeof accountDetails.remainingBalance);
    //     if (typeof accountDetails.remainingBalance === "number" || !accountDetails.remainingBalance) {
    //         console.log('testesd123')
    //         return;
    //     } else {
    //         return;
    //
    //     }
    // }, [accountDetails.remainingBalance])
    //
    useEffect(() => {
        filterTheRemBalanceInput(remainingBalance);
    }, [remainingBalance])


    return (
        <div>
            <Modal show={props.showModal} onHide={props.handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create an Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formUserName">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter User Name" name="username"
                                          value={accountDetails.username || ""} onChange={handleChange}/>

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" name="password"
                                          value={accountDetails.password || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formAccountNumber">
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter Account Number" name="accountNumber"
                                          value={accountDetails.accountNumber || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTransferPass">
                            <Form.Label>Transfer Pass</Form.Label>
                            <Form.Control type="password" placeholder="Enter Transfer Password" name="transferPass"
                                          value={accountDetails.transferPass || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDestination">
                            <Form.Label>Destination</Form.Label>
                            <Form.Control type="text" placeholder="Enter Destination" name="destination"
                                          value={accountDetails.destination || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRemainingBalance">
                            <Form.Label>Remaining Balance</Form.Label>
                            <Form.Control type="text" placeholder="Enter Remaining Balance in Rials"
                                          name="remainingBalance" value={remainingBalance || ''}
                                          maxLength="15"
                                          onChange={handleChange}/>
                            <Form.Text className="text-muted">
                                Remaining Balance must be in Rial not Toman.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTransferPass" style={{
                            display: "flex",
                            justifyContent: "end",
                            alignContent: "center",
                            alignItems: "center"
                        }}>
                            <Form.Label style={{marginRight: "10px", marginTop: "10px"}}>Active</Form.Label>
                            <Form.Check
                                style={{fontSize: "1.5rem"}}
                                type="switch"
                                id="custom-switch"
                                name="active"
                                value={accountDetails.active || false}
                                onClick={handleChange}
                            />
                        </Form.Group>
                        {/*<Form.Group className="mb-3" controlId="formBasicCheckbox">*/}
                        {/*    <Form.Check type="checkbox" label="Check me out" />*/}
                        {/*</Form.Group>*/}
                        {/*<Button variant="primary" type="submit">*/}
                        {/*    Submit*/}
                        {/*</Button>*/}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Col xs={12} className="justify-content-center align-items-center d-flex">
                        <Button variant="success" style={{width: "35%"}} onClick={createAccount}>
                            <span>
                                {createAccountPending
                                    ?
                                    <Spinner animation="border" role="status"
                                             style={{width: "1.3rem", height: "1.3rem"}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    : "Submit"
                                }
                            </span>
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CreateAccountModal;