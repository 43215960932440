import logo from './logo.svg';
import './App.css';

import Layout from "./components/Layout";

function App() {
  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
