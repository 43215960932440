import axios from "axios";

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    baseUrl: 'https://Offline.c2cbrg.com/api'
};

