import './stlyes/CardInfo.css';
import {Button, Row, Container, Col, Spinner} from 'react-bootstrap';
import apiServiceWithToken from "../apiService";
import apiService from "../apiService";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";

function Logs(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [logsData, setLogsData] = useState({});

    async function getLogs() {
        setIsLoading(true);
        try {
            const response = await apiServiceWithToken.get(`${apiService.baseUrl}/get-latest-logs`);
            setLogsData(response.data);
            setIsLoading(false);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Confirm',
                timer: 5000,
                timerProgressBar: true,
            })
            console.error(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getLogs();
    }, [])

    useEffect(() => {
        if (props.refreshSignal) getLogs()
    }, [props.refreshSignal])


    return (
        <div>
            {!isLoading ? <Container>
                {logsData.length > 0 ? logsData.map((log, index) => {
                    if (log.createdAt && log.message) {
                        return (
                            <Row className="eachRow cardContainer">
                                <Col xs={6}>
                                    <span>
                                        {new Date(log.createdAt).toLocaleString()}:
                                    </span>
                                            </Col>
                                            <Col xs={6}>
                                    <span>
                                        {log.message}
                                    </span>
                                </Col>
                            </Row>
                        )
                    }
                }) : ""}
            </Container> : <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>}
        </div>
    );
}

export default Logs;
